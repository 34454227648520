import React from 'react';

const ErrorPage = () => (
  <div className="text-center">
    <h2>An error occured.</h2>
    <p>Please reload the page.</p>
  </div>
);

export default ErrorPage;
