import React from 'react';
import auth from '../helpers/auth';
import { withRouter } from 'react-router';
import Spinner from 'react-spinner';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      loading: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ loading: true });

    const username = this.refs.username.value;
    const password = this.refs.password.value;

    const loginCheck = (loggedIn, error) => {
      this.setState({ loading: false });
      if (!loggedIn) {
        return this.setState({ error: error });
      }

      const { location } = this.props;

      if (location.state && location.state.nextPathname) {
        this.props.router.replace(location.state.nextPathname);
      } else {
        this.props.router.replace('/country-home');
      }
    };

    auth.login(username, password, loginCheck);
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2">
            <h2>
              Reporting to the Programme of Action on small arms and light
              weapons 2024
            </h2>
            <p>
              National reports are the primary tool to assess the implementation
              of the Programme of Action.
            </p>
            <p>
              This reporting template was welcomed by all States in 2010. It
              closely follows the Programme of Action and its International
              Tracing Instrument. Not all areas may be applicable or a priority
              to every state. Therefore, please note that there are no ‘right’
              or ‘wrong’ answers. After having provided a full report, an update
              on relevant sections every two years suffices.
            </p>
            <p>
              Only formally identified National Points of Contact or the
              Permanent Missions of Member States can submit a national report.
            </p>
            <p>
              The reporting template can be downloaded. Draft versions can be
              saved and printed, in order to obtain internal approval where
              necessary, before submission.
            </p>
            <p>&nbsp;</p>

            <div className="panel panel-default">
              <div className="panel-heading text-center">
                <h3>Log in</h3>
              </div>

              <div className="panel-body">
                <div className="row">
                  <div className="col-md-6 col-md-offset-3 text-center">
                    <form
                      className="form-signin"
                      onSubmit={this.handleSubmit.bind(this)}
                      autoComplete="off"
                    >
                      <span id="reauth-email" className="reauth-email" />

                      <input
                        type="text"
                        ref="username"
                        className="form-control"
                        placeholder="Username"
                        required
                      />
                      <input
                        type="password"
                        ref="password"
                        className="form-control"
                        placeholder="Password"
                        required
                      />

                      <button
                        className="btn btn-default btn-block btn-signin"
                        type="submit"
                      >
                        Log in
                      </button>
                      {this.state.loading && <Spinner />}

                      {this.state.error && (
                        <div className="alert alert-danger" role="alert">
                          Please try again, username or password is incorrect.
                        </div>
                      )}
                    </form>
                    <div className="text-center">
                      <a href="mailto:conventionalarms-unoda@un.org">
                        <p className="small">Forgot your password?</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
