import $ from 'jquery';
import Promise from 'promise-polyfill';
import jwtAuth from '../helpers/auth';
import { FILE_SUBMISSION_ENDPOINT, FILE_DELETION_ENDPOINT } from '../endpoints';
if (!window.Promise) {
  window.Promise = Promise;
}

export function doFilePosts(files, reportId, callbacks) {
  const { onDoing, onSuccess, onFail } = callbacks;
  if (files === null || typeof files === 'undefined' || files.length < 1) {
    return Promise.resolve();
  }
  onDoing();
  const handleFilePost = postAllFiles(files, reportId)
    .then(() => {
      onSuccess();
    })
    .catch(err => {
      onFail();
    });
  return handleFilePost;
}

function postAllFiles(files, reportId) {
  const promisesArray = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const filePromise = postFile(file, reportId);
    promisesArray.push(filePromise);
  }
  return Promise.all(promisesArray);
}

function postFile(fileCustomObject, reportId) {
  /* fileCustomObject is an object that looks like: {file: FileObject, questionId: 12345} */
  /* questionFormResponseId is the ID of the report to which a file need to belong */

  const formData = new FormData();
  formData.append('file', fileCustomObject.file);

  const jsPromise = Promise.resolve(
    $.ajax({
      url: FILE_SUBMISSION_ENDPOINT,
      type: 'POST',
      data: formData,
      headers: {
        Authorization: 'jwt ' + jwtAuth.getToken(),
        'X-FORM-FIELD-ID': fileCustomObject.questionId,
        'X-FORM-RESPONSE-ID': reportId,
        'Content-Disposition': 'attachment; filename=' + fileCustomObject.name,
      },
      cache: false,
      contentType: false,
      enctype: 'multipart/form-data',
      processData: false,
    }),
  );
  return jsPromise;
}

export function doFileDeletes(files, callbacks) {
  const { onDoing, onSuccess, onFail } = callbacks;
  if (files === null || typeof files === 'undefined' || files.length < 1) {
    return Promise.resolve();
  }

  onDoing();

  return deleteAllFiles(files)
    .then(() => {
      onSuccess();
    })
    .catch(err => {
      onFail();
    });
}

function deleteAllFiles(files) {
  const promisesArray = [];
  for (let i = 0; i < files.length; i++) {
    const filePromise = deleteFile(files[i]);
    promisesArray.push(filePromise);
  }
  return Promise.all(promisesArray);
}

function deleteFile(file) {
  const filePathEncoded = encodeURI(file.filePath);
  const jsPromise = Promise.resolve(
    $.ajax({
      url: FILE_DELETION_ENDPOINT + filePathEncoded,
      type: 'DELETE',
      headers: {
        Authorization: 'jwt ' + jwtAuth.getToken(),
        //'X-FORM-FIELD-ID': questionId,
        //'X-FORM-RESPONSE-ID': reportId,
      },
    }),
  );
  return jsPromise;
}

/**
 * Gets the numbers from a string
 * @param input {string} String to get number from
 * @returns {number} Number from the string
 */
export function getNumberFromString(input) {
  let regex = new RegExp('\\d+', 'g');
  let match;
  let output = '0';
  while ((match = regex.exec(input))) output += match[0];
  return parseInt(output, 10);
}

/**
 * Gets all the files from a DOM Form
 * @returns {Array.<{formFieldId:string,file:File}>} Array of Files from the form in the DOM
 */
export function getFiles() {
  let files = [];
  let fileInputList = $('input[type=file]');
  if (fileInputList != null) {
    for (let i = 0; i < fileInputList.length; i++) {
      if (fileInputList[i].files != null) {
        for (let o = 0; o < fileInputList[i].files.length; o++)
          files.push({
            formFieldId: getNumberFromString($(fileInputList[i]).attr('id')),
            file: fileInputList[i].files[o],
          });
      }
    }
  }
  return files;
}
