import React from 'react';
import { Link } from 'react-router';

const NoMatch = () => (
  <div className="text-center">
    <h2>
      <small>404</small>{' '}
    </h2>
    <h2>Page could not be found </h2>
    <p>
      {' '}
      Back to <Link to="/country-home"> homepage </Link> of POA Reporting
    </p>
  </div>
);

export default NoMatch;
