import React from 'react';
import { render } from 'react-dom';
import auth from './helpers/auth';
import { Router, Route, Redirect, browserHistory } from 'react-router';
import NoMatch from './components/No-match';
import App from './components/App';
import CountryHomePage from './components/Country-home-page';
import ReportReadOnly from './components/Report-read-only';
import ReportEdit from './components/Report-edit';
import Login from './components/Login';
import Logout from './components/Logout';
import ErrorPage from './components/Error-page';

window.React = React;

function requireAuth(nextState, replace) {
  if (!auth.loggedIn()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
}

render(
  <Router history={browserHistory}>
    <Redirect from="/" to="/country-home" />
    <Route path="/" component={App}>
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route
        path="/country-home"
        component={CountryHomePage}
        onEnter={requireAuth}
      />
      <Redirect from="/report" to="/country-home" />
      <Route path="/error" component={ErrorPage} />
      <Route
        path="/report/:winterfellAnswerId"
        component={ReportReadOnly}
        onEnter={requireAuth}
      />
      <Route
        path="/report/:winterfellAnswerId/edit"
        component={ReportEdit}
        onEnter={requireAuth}
      />
      <Route path="*" component={NoMatch} />
    </Route>
    {/* This is the "catch-all"-Route */}
  </Router>,
  document.getElementById('root'),
);
