import React from 'react';
import Winterfell from 'winterfell';
import UploadedFileManager from './Uploaded-file-manager';
import { makeFormEditableEventHandlers } from '../../helpers/winterfell-eventhandlers';
import { provideWinterfellSchemaWithClasses } from '../../helpers/winterfell-css-classes';
import { manageTextAreaExpansion } from '../../helpers/winterfell-style-hacks';

export default class WinterfellEditable extends React.Component {
  render() {
    const { schema, questionAnswers, onSave, onSubmit, onUpdate } = this.props;
    const schemaWithClasses = provideWinterfellSchemaWithClasses(
      schema,
      true,
    ); /* true stands for non-editable */
    Winterfell.addInputType('fileInput', props => (
      <UploadedFileManager
        onFileDropCallback={this.props.onDropzoneDrop}
        onFileDeleteSelectCallback={this.props.onFileDeleteSelectCallback}
        {...props}
      />
    ));

    return (
      <Winterfell
        schema={schemaWithClasses}
        questionAnswers={Object.assign({}, questionAnswers)}
        onSwitchPanel={() => {
          makeFormEditableEventHandlers.onSwitchPanel();
          manageTextAreaExpansion();
        }}
        onRender={() => {
          makeFormEditableEventHandlers.onRender();
        }}
        disableSubmit={true}
        onSave={onSave}
        onSubmit={onSubmit}
        onUpdate={onUpdate}
      />
    );
  }
}
