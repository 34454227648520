import React from 'react';
import { browserHistory } from 'react-router';
import Spinner from 'react-spinner';
import NoMatch from './No-match';
import { PDF_ENDPOINT } from '../endpoints';
import WinterfellEditable from './edit-report-components/Winterfell-editable';
import ReportHeader from './Report-header';
import Announcement from './Announcements';
import {
  doFilePosts,
  doFileDeletes,
  getNumberFromString,
} from '../helpers/file-helpers';
import {
  fetchReportData,
  fetchWinterfellSchema,
  doPatchWinterfell,
} from '../helpers/fetch-helpers';
import {
  PatchStatusMessage,
  FilePostStatusMessage,
  FileDeleteStatusMessage,
} from './edit-report-components/Status-messages';

export default class ReportEdit extends React.Component {
  constructor(props) {
    super(props);

    this.filesToBeDeleted = {}; /* must not be a state variable otherwise it triggers a rerendering of winterfell... */
    this.filesToBeUploaded = {}; /* must not be a state variable otherwise it triggers a rerendering of winterfell... */

    this.state = {
      winterfellSchema: null,
      winterfellData: null,
      winterfellDataUnsaved: false,

      dataFetchError: false,

      patchBeingSend: false,
      patchResponse: null,

      filePostBeingMade: false,
      filePostResponse: null,

      fileDeleteBeingMade: false,
      fileDeleteResponse: null,

      finalSubmitSuccessful: false,
      hasReadReportAnnouncement: false,
    };
  }

  /* this function is only a hook that DropzoneWrapper gets */
  setFilesToBeUploaded(files, questionId) {
    const quId = getNumberFromString(questionId);
    this.filesToBeUploaded[quId] = files.map(f => ({
      questionId: quId,
      file: f,
    }));
  }
  /* this function is only a hook that DropzoneWrapper gets */
  setFilesToBeDeleted(files, questionId) {
    const quId = getNumberFromString(questionId);
    this.filesToBeDeleted[quId] = files;
  }

  fetchReport(winterfellAnswerId, countryIsoCode) {
    fetchReportData(
      winterfellAnswerId,
      countryIsoCode,
      this.dataFetchCallbacks,
    ).then(({ winterfellFormId, language }) =>
      fetchWinterfellSchema(
        winterfellFormId,
        this.schemaFetchCallbacks,
        this.props.location.query.lang || language,
      ),
    );
  }

  resetStatusOfAllOperations() {
    this.setState({
      patchBeingSend: false,
      patchResponse: null,

      filePostBeingMade: false,
      filePostResponse: null,

      fileDeleteBeingMade: false,
      fileDeleteResponse: null,
    });
  }

  patchingCallbacks = {
    onDoing: () => {
      this.setState({
        patchBeingSend: true,
        patchResponse: null,
      });
    },
    onSuccess: () => {
      this.setState({
        patchBeingSend: false,
        patchResponse: 'success',
        winterfellDataUnsaved: false,
      });
    },
    onFail: () => {
      this.setState({
        patchBeingSend: false,
        patchResponse: 'error',
        winterfellDataUnsaved: true,
      });
    },
  };

  patchingCallbacksForSubmit = {
    onDoing: () => {
      this.setState({
        patchBeingSend: true,
        patchResponse: null,
      });
    },
    onSuccess: () => {
      this.setState({
        patchBeingSend: false,
        patchResponse: 'success',
        finalSubmitSuccessful: true,
      });
    },
    onFail: () => {
      this.setState({
        patchBeingSend: false,
        patchResponse: 'error',
        winterfellDataUnsaved: true,
      });
    },
  };

  filePostingCallbacks = {
    onDoing: () => {
      this.setState({
        filePostBeingMade: true,
        filePostResponse: null,
      });
    },
    onSuccess: () => {
      this.setState({
        filePostBeingMade: false,
        filePostResponse: 'success',
      });
      this.filesToBeUploaded = {};
    },
    onFail: () => {
      this.setState({
        filePostBeingMade: false,
        filePostResponse: 'error',
      });
      this.filesToBeUploaded = {};
    },
  };

  fileDeletingCallbacks = {
    onDoing: () => {
      this.setState({
        fileDeleteBeingMade: false,
        fileDeleteResponse: 'success',
      });
    },
    onSuccess: () => {
      this.setState({
        fileDeleteBeingMade: false,
        fileDeleteResponse: 'success',
      });
      this.filesToBeDeleted = {};
    },
    onFail: () => {
      this.setState({
        fileDeleteBeingMade: false,
        fileDeleteResponse: 'error',
      });
      this.filesToBeDeleted = {};
    },
  };

  dataFetchCallbacks = {
    onSuccess: data => {
      this.setState({ winterfellData: data });
    },
    onFail: () => this.setState({ dataFetchError: true }),
  };

  schemaFetchCallbacks = {
    onSuccess: schema => this.setState({ winterfellSchema: schema }),
    onFail: () => this.setState({ dataFetchError: true }),
  };

  saveAnswersAndFiles(isSubmit) {
    const data = Object.assign({}, this.state.winterfellData);
    if (isSubmit) {
      data.questionDraft = false;
    }
    const reportId = this.state.winterfellData.questionFormResponseId;
    const { countryIsoCode } = this.props;
    this.resetStatusOfAllOperations();

    doPatchWinterfell(
      reportId,
      data,
      isSubmit ? this.patchingCallbacksForSubmit : this.patchingCallbacks,
    )
      .then(() => {
        return doFileDeletes(
          Object.keys(this.filesToBeDeleted).reduce((out, key) => {
            return out.concat(this.filesToBeDeleted[key]);
          }, []),
          this.fileDeletingCallbacks,
        );
      })
      .then(() => {
        return doFilePosts(
          Object.keys(this.filesToBeUploaded).reduce((out, key) => {
            return out.concat(this.filesToBeUploaded[key]);
          }, []),
          reportId,
          this.filePostingCallbacks,
        );
      })
      .then(() => {
        if (isSubmit) {
          browserHistory.push('/country-home');
        }
      })
      .then(() => {
        return this.fetchReport(reportId, countryIsoCode);
      })
      .catch(err => {
        console.error(err);
      });
  }

  fetchReportWhenReadyIfNotAlreadyThere(countryIsoCode) {
    if (this.state.winterfellData) {
      return; //dont fetch again...
    }
    const { winterfellAnswerId } = this.props.params;
    /* countryIsoCode will be an empty string (not null) when an admin/staff is logged in */
    if (countryIsoCode !== null) {
      this.fetchReport(winterfellAnswerId, countryIsoCode);
    }
  }

  componentDidMount() {
    const { countryIsoCode } = this.props;
    this.fetchReportWhenReadyIfNotAlreadyThere(countryIsoCode);
  }

  componentWillReceiveProps(nextProps) {
    const { countryIsoCode, userIsStaff } = nextProps;
    this.setState({
      userIsStaff: userIsStaff,
    });
    this.fetchReportWhenReadyIfNotAlreadyThere(countryIsoCode);
  }

  shouldDisplayAnnouncement() {return !this.state.hasReadReportAnnouncement;}

  onWinterfellUpdate(questionAnswers) {
    this.setState({
      winterfellData: questionAnswers,
      winterfellDataUnsaved: true,
    });
    this.resetStatusOfAllOperations();
  }

  showWinterfellForm() {
    const { patchBeingSend, winterfellSchema, winterfellData } = this.state;
    const { finalSubmitSuccessful } = this.state;
    if (this.shouldDisplayAnnouncement()) {
      return (<Announcement onClick={()=> this.setState({ hasReadReportAnnouncement: true})}/>)
    }

    if (winterfellSchema && winterfellData) {
      const formActivityClasses =
        patchBeingSend || finalSubmitSuccessful
          ? 'winterfell-container disabled-form'
          : 'winterfell-container';
      return (
        <div className={formActivityClasses}>
          <WinterfellEditable
            schema={winterfellSchema}
            questionAnswers={Object.assign({}, winterfellData)}
            onSave={() => {
              this.saveAnswersAndFiles();
            }}
            onSubmit={() => {
              this.saveAnswersAndFiles(true);
            }}
            onUpdate={questionAnswers =>
              this.onWinterfellUpdate(questionAnswers)
            }
            onDropzoneDrop={(files, questionId) => {
              this.setFilesToBeUploaded(files, questionId);
            }}
            onFileDeleteSelectCallback={(files, questionId) => {
              this.setFilesToBeDeleted(files, questionId);
            }}
          />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      dataFetchError,
      winterfellSchema,
      winterfellData,
      winterfellDataUnsaved,
      patchBeingSend,
      patchResponse,
      finalSubmitSuccessful,
      filePostBeingMade,
      filePostResponse,
      fileDeleteBeingMade,
      fileDeleteResponse,
      userIsStaff,
    } = this.state;

    if (dataFetchError) {
      return <NoMatch />;
    }

    const showEditSubmitWarning =
      winterfellData &&
      winterfellSchema &&
      winterfellData.questionDraft === false &&
      !finalSubmitSuccessful;

    if (winterfellData) {
      if (
        winterfellData.questionDraft === false ||
        winterfellData.questionArchived === true
      ) {
        return <NoMatch />;
      }
      return (
        <div className="main-content poa-questionaire">
          <ReportHeader
            country={winterfellData.questionCountry}
            reportingYear={winterfellData.questionReportingYear}
            isStaff={userIsStaff}
            isDraft={winterfellData.questionDraft}
            isArchived={winterfellData.questionArchived}
            hasUnsaved={winterfellDataUnsaved}
            reportId={this.props.params.winterfellAnswerId}
            patchingCallbacks={this.patchingCallbacks}
            disableButton={patchBeingSend}
            pdfLink={PDF_ENDPOINT + winterfellData.questionFormResponseId}
            onSave={() => this.saveAnswersAndFiles()}
          />
          <div>{this.showWinterfellForm()}</div>
          <div className="edit-report-feedback-area">
            {(patchBeingSend || patchResponse) && (
              <PatchStatusMessage
                isActive={patchBeingSend}
                response={patchResponse}
                isFinal={finalSubmitSuccessful}
              />
            )}
            {(fileDeleteBeingMade || fileDeleteResponse) && (
              <FileDeleteStatusMessage
                isActive={fileDeleteBeingMade}
                response={fileDeleteResponse}
              />
            )}
            {(filePostBeingMade || filePostResponse) && (
              <FilePostStatusMessage
                isActive={filePostBeingMade}
                response={filePostResponse}
              />
            )}
          </div>

          {showEditSubmitWarning && (
            <div className="alert alert-warning alert-pure">
              <strong>Warning</strong>: You are editing a submitted report!
            </div>
          )}
        </div>
      );
    }
    return <Spinner />;
  }
}
