const WFCssClasses = {
  form: 'winterfell-form',
  input: 'form-control',
  select: 'form-control',
  question: 'form-group wf-question',
  radioListItem: 'radio',
  radioList: 'clean-list',
  checkboxInput: 'checkbox',
  checkboxListItem: 'checkbox',
  checkboxList: 'clean-list',
  controlButton: 'btn btn-default pull-right',
  backButton: 'btn btn-default pull-left',
  errorMessage: 'alert alert-danger',
  questionPostText: 'push-top',
  buttonBar: 'wf-button-bar',
  questionSetHeaderContainer: 'wf-question-set-header-container',
  questionSets: 'panel-body wf-question-sets',
  questionPanelHeaderContainer: 'panel-heading wf-panel-heading',
  questionPanel: 'panel wf-panel',
  saveButton: 'btn btn-primary middle-button',
  submitButton: 'btn btn-warning middle-button submit-button'
};

const WFReadOnlyCssClasses = {
  questionSets: 'panel-body wf-question-sets  wf-question-sets-view-only',
  questionPanel: 'panel wf-panel wf-panel-view-only'
};

export function provideWinterfellSchemaWithClasses(
  winterfellSchema,
  formIsEditable
) {
  if (!winterfellSchema) {
    return null;
  }
  const baseStyle = Object.assign({}, WFCssClasses);
  const readOnlyStyle = Object.assign({}, baseStyle, WFReadOnlyCssClasses);
  const winterfellClasses = formIsEditable ? baseStyle : readOnlyStyle;
  return Object.assign({}, winterfellSchema, { classes: winterfellClasses });
}
