import React, { Component } from 'react';
import { Table, unsafe } from 'reactable';
import _ from 'underscore';
import UpdateSubmitted from './Update-Submitted';
import { EDIT_TYPES } from '../helpers/permissions.js';
import { parseReportId } from '../helpers/various-helpers';

export default class ReportsTableComprehensive extends Component {
  constructor(props) {
    super();
    this.state = {
      reports: this.renderDataForTable(props.reports),
      showArchived: false,
    };
  }

  renderDataForTable(reportsArray) {
    const renderedData = reportsArray.map(r => {
      const idWithoutPrefix = parseReportId(r.id);
      const viewReportUrl = `/report/${idWithoutPrefix}`;
      const editReportUrl = `/report/${idWithoutPrefix}/edit`;

      const viewReportLink =
        r.isLegacy === false ? `<a href=${viewReportUrl}>View report</a>` : '';

      let editReportThing = '';
      if (r.editType === EDIT_TYPES.COPY) {
        editReportThing = <UpdateSubmitted reportId={idWithoutPrefix} />;
      } else if (r.editType === EDIT_TYPES.PATCH) {
        editReportThing = unsafe(`<a href=${editReportUrl}>Edit report</a>`);
      }
      const pdfLink = `<a href=${r.pdfLink} target="_blank">Download</a>`;
      // eslint-disable-next-line
      const submittedSign =
        "<span class='glyphicon glyphicon-ok-circle text-success' aria-hidden='true'></span>&nbsp;Submitted";
      const templateVersionName = r.templateVersion
        ? r.templateVersion + ' Template'
        : '-';

      let status = '-';
      if (r.draft === true) {
        status = 'Draft';
      } else if (r.draft === false) {
        status = 'Submitted';
      }

      let isArchived = '-';
      if (r.archived === true) {
        isArchived = 'Archived';
      } else if (r.archived === false) {
        isArchived = 'Not archived';
      }

      return Object.assign(
        {},
        {
          reportingYear: r.reportingYear,
          isLegacy: r.isLegacy === true ? '-' : 'Online',
          status: status,
          isArchived: isArchived,
          language: r.language,
          templateVersion: templateVersionName,
          link: unsafe(viewReportLink),
          pdfLink: unsafe(pdfLink),
          edit: editReportThing,
        },
      );
    });
    return renderedData;
  }

  onFilterChange(event) {
    this.setState({
      showArchived: !this.state.showArchived,
    });
  }

  render() {
    const that = this;
    const { showArchived, reports } = this.state;
    const finalData =
      showArchived === false
        ? _.filter(reports, d => d.isArchived !== 'Archived')
        : reports;
    const columns = [
      { key: 'reportingYear', label: 'Year' },
      { key: 'isLegacy', label: 'Report type' },
      { key: 'status', label: 'Status' },
      { key: 'isArchived', label: 'Archived' },
      { key: 'language', label: 'Language' },
      { key: 'templateVersion', label: 'Template version' },
      { key: 'link', label: 'Link' },
      { key: 'pdfLink', label: 'PDF' },
      { key: 'edit', label: 'Edit report' },
    ];
    if (showArchived === false) {
      columns.splice(3, 1);
    }
    return (
      <span>
        <div className="checkbox">
          <label>
            <input
              onChange={that.onFilterChange.bind(this)}
              type="checkbox"
              name="showArchived"
              checked={this.state.showArchived}
            />Also show archived reports
          </label>
        </div>
        <Table
          className="table table-striped table-bordered"
          data={finalData}
          sortable={true}
          noDataText="There are no reports based on your selection"
          columns={columns}
        />
      </span>
    );
  }
}
