import React from 'react';
import { Button } from 'react-bootstrap';
import { browserHistory } from 'react-router';
import { createCopy } from '../helpers/fetch-helpers';
import Spinner from 'react-spinner';
import { copyWarning } from '../helpers/prompts';

const REPORTING_YEAR = 2024

class UpdateSubmitted extends React.Component {
  constructor() {
    super();
    this.state = {
      isCopying: false,
      error: false,
    };
  }

  handleClick() {
    this.setState({ isCopying: true });
    createCopy(this.props.reportId, REPORTING_YEAR)
      .then(({ questionFormResponseId, _ }) => {
        browserHistory.push('/report/' + questionFormResponseId + '/edit');
      })
      .catch(err => {
        this.setState({ isCopying: false, error: true });
        console.log('Report could not be copied');
      });
  }

  render() {
    return (
      <div>
        {this.state.isCopying && <Spinner />}
        <Button
          className="btn btn-default btn-sm"
          onClick={() => {
            window.confirm(copyWarning) && this.handleClick();
          }}
          disabled={this.state.isCopying}
        >
          {
            <span>
              Update and resubmit
            </span>
          }
        </Button>
        {this.state.error && <small>An error occured</small>}
      </div>
    );
  }
}

export default UpdateSubmitted;
