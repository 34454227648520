import jwtAuth from '../helpers/auth';
import {
  REPORTLIST_ENDPOINT,
  WINTERFELL_SCHEMA_ENDPOINT,
  COPY_ENDPOINT,
} from '../endpoints';
import Promise from 'promise-polyfill';
if (!window.Promise) {
  window.Promise = Promise;
}

export function fetchWinterfellSchema(winterfellFormId, callbacks, language) {
  let endpoint = WINTERFELL_SCHEMA_ENDPOINT + winterfellFormId;
  endpoint += language ? '?language__iso_639_2=' + language : '';
  const { onSuccess, onFail } = callbacks;
  fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'jwt ' + jwtAuth.getToken(),
    },
  })
    .then(response => {
      if (!response.ok) {
        onFail();
        return Promise.reject('Failed to fetch Winterfell Schema');
      }
      return response;
    })
    .then(response => response.json())
    .then(result => {
      const schema = result.data;
      onSuccess(schema);
    });
}

export function fetchReportData(winterfellAnswerId, countryIsoCode, callbacks) {
  const { onSuccess, onFail } = callbacks;
  const endpoint = REPORTLIST_ENDPOINT + winterfellAnswerId;
  const jsFetch = fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'jwt ' + jwtAuth.getToken(),
    },
  })
    .then(response => {
      if (!response.ok) {
        onFail();
        return Promise.reject();
      }
      return response;
    })
    .then(response => response.json())
    .then(result => {
      var data = result.data;
      const isAdmin = countryIsoCode === '';
      if (!isAdmin && data.questionCountryIsoAlpha_3 !== countryIsoCode) {
        /* this report belongs to someone else! */
        onFail();
        return Promise.reject();
      }
      onSuccess(data);
      return Promise.resolve({
        winterfellFormId: data.questionFormId,
        language: data.questionLanguageIso_639_2,
      });
    });
  return jsFetch;
}

export function doArchiveWinterfell(winterfellAnswerId, callbacks) {
  const data = { questionArchived: true };
  return doPatchWinterfell(winterfellAnswerId, data, callbacks);
}

export function doPatchWinterfell(
  winterfellAnswerId,
  questionAnswers,
  callbacks,
) {
  const { onDoing, onSuccess, onFail } = callbacks;
  onDoing();
  const data = Object.assign({}, questionAnswers);
  delete data.questionFormResponseId;

  const endpoint = REPORTLIST_ENDPOINT + winterfellAnswerId;
  const patchParams = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'jwt ' + jwtAuth.getToken(),
    },
    body: JSON.stringify(data),
  };

  const handlePatch = fetch(endpoint, patchParams)
    .then(response => {
      if (!response.ok) {
        throw Error(response.status);
      }
      return response;
    })
    .then(() => onSuccess())
    .catch(err => {
      onFail();
    });
  return handlePatch;
}

export function getFilenameFromPath(path) {
  const pieces = path.split('/');
  return decodeURIComponent(pieces[pieces.length - 1]);
}

export function createCopy(reportId, newYear) {
  const endpoint = COPY_ENDPOINT + newYear +"/" + reportId;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'jwt ' + jwtAuth.getToken(),
    },
  })
    .then(response => {
      if (!response.ok) {
        return Promise.reject('Failed to copy report');
      }
      return response;
    })
    .then(response => response.json())
    .then(response => response.data);
}
