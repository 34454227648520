import React from 'react';
import NewReportCreator from './New-report-creator';

/* when year is present: widget offers new reportcreator, 
when year is falsy: widget assumes that a report is already created */

const NewReportWidget = ({ year, countryIsoCode }) => {
  const coveringYears = year => [year - 2, year - 1]; //biannual reporting period...
  const canDoNewReport = !!year;
  return (
    <div className="panel panel-info">
      <div className="panel-heading">
        <h4>
          Create a <strong>new</strong> report
        </h4>
      </div>
      <div className="panel-body">
        {canDoNewReport ? (
          <NewReportCreator
            reportYear={year}
            coveringYears={coveringYears(year)}
            countryIsoCode={countryIsoCode}
          />
        ) : (
          <strong>
            <span
              className="glyphicon glyphicon-ok"
              style={{ marginRight: '10px' }}
              aria-hidden="true"
            />You already created a report for the current reporting period.
          </strong>
        )}
      </div>
    </div>
  );
};

export default NewReportWidget;
