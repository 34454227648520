import React, { Component } from 'react';

export default class PoaFooter extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="pull-left">
            <a href="http://www.un.org" title="United Nations">
              <span>United Nations</span>
            </a>
          </div>
          <div className="pull-right">
            <ul className="menu nav">
              <li>
                <a href="mailto:conventionalarms-unoda@un.org">Contact</a>
              </li>
              <li>
                <a href="https://www.un.org/en/about-us/copyright">
                  Copyright{' '}
                </a>
              </li>
              <li>
                <a href="https://www.un.org/en/about-us/fraud-alert">
                  Fraud Alert
                </a>
              </li>
              <li>
                <a href="https://www.un.org/en/about-us/privacy-notice">
                  Privacy Notice
                </a>
              </li>
              <li>
                <a href="https://www.un.org/en/about-us/terms-of-use">
                  Terms of Use
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
