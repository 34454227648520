import React from 'react';
import Spinner from 'react-spinner';

export const GenericStatusMessage = ({ status, message }) => {
  switch (status) {
    case 'active':
      return (
        <span>
          {' '}
          {message} <Spinner />{' '}
        </span>
      );
    case 'error':
      return (
        <div className="alert alert-danger alert-small">
          <span className="glyphicon glyphicon-remove" aria-hidden="true">
            &nbsp;
          </span>{' '}
          {message}{' '}
        </div>
      );
    case 'success':
      return (
        <div className="alert alert-success alert-small alert-pure">
          <span className="glyphicon glyphicon-ok">&nbsp;</span> {message}{' '}
        </div>
      );
    default:
      return null;
  }
};

export const PatchStatusMessage = ({ isActive, response, isFinal }) => {
  const status = isActive ? 'active' : response;
  let message = '';
  switch (status) {
    case 'active':
      message = 'Saving your changes...';
      break;
    case 'error':
      message = 'Could not save your changes';
      break;
    case 'success':
      message = isFinal
        ? 'Final submit succesful!'
        : 'Your changes have been saved';
      break;
    default:
      message = '';
  }
  return <GenericStatusMessage status={status} message={message} />;
};

export const FilePostStatusMessage = ({ isActive, response }) => {
  const status = isActive ? 'active' : response;
  let message = '';
  switch (status) {
    case 'active':
      message = 'Uploading files...';
      break;
    case 'error':
      message = 'There was a problem uploading a file';
      break;
    case 'success':
      message = 'Your files were uploaded successfully.';
      break;
    default:
      message = '';
  }
  return <GenericStatusMessage status={status} message={message} />;
};

export const FileDeleteStatusMessage = ({ isActive, response }) => {
  const status = isActive ? 'active' : response;
  let message = '';
  switch (status) {
    case 'active':
      message = 'Deleting files...';
      break;
    case 'error':
      message = 'There was a problem deleting a file';
      break;
    case 'success':
      message = 'Your selected files have been deleted';
      break;
    default:
      message = '';
  }
  return <GenericStatusMessage status={status} message={message} />;
};
