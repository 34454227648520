import React from 'react';
import Winterfell from 'winterfell';
import { makeFormReadOnlyEventHandlers } from '../helpers/winterfell-eventhandlers';
import { provideWinterfellSchemaWithClasses } from '../helpers/winterfell-css-classes';
import { manageTextAreaExpansion } from '../helpers/winterfell-style-hacks';
import FilePathList from './File-path-list';

const manageButtonDisplayOnSwitchPanel =
  makeFormReadOnlyEventHandlers.onSwitchPanel;
const manageButtonDisplayOnRender = makeFormReadOnlyEventHandlers.onRender;

export default class WinterfellReadOnly extends React.Component {
  render() {
    const { schema, questionAnswers } = this.props;
    const schemaWithClasses = provideWinterfellSchemaWithClasses(
      schema,
      false
    ); /* false stands for non-editable */
    Winterfell.addInputType('fileInput', props => (
      <FilePathList filePaths={props.value} />
    ));

    return (
      <Winterfell
        schema={schemaWithClasses}
        questionAnswers={Object.assign({}, questionAnswers)}
        onSwitchPanel={() => {
          manageButtonDisplayOnSwitchPanel();
          manageTextAreaExpansion();
        }}
        onRender={() => {
          manageButtonDisplayOnRender();
        }}
        disableSubmit={true}
        onSave={() => {}}
        onSubmit={() => {}}
        onUpdate={() => {}}
      />
    );
  }
}
