import React from 'react';


export default ({onClick}) => (
        <div>
          <div>
            <p>
            Programme of Action to Prevent, Combat and Eradicate the Illicit Trade in Small Arms and Light Weapons in All Its Aspects (PoA)
and its International Instrument to Enable States to Identify and Trace, in a Timely and Reliable Manner, Illicit Small Arms and Light Weapons (ITI)
            </p>
            <ul>
              <li>
              The national report is the primary tool to assess implementation of the Programme of Action.
In 2008, States requested further standardisation in reporting.
This reporting template was developed, combining user-friendliness with increased analytical value, and was welcomed by all States in 2010.
It closely follows the Programme of Action and the International Tracing Instrument.
              </li>
              <li>
              It was updated in 2017, 2019, 2021 and 2023 to reflect consensus outcome documents.
              </li>
              <li>
              Not all areas may be applicable or a priority to every state. Therefore, there are no ‘right’ or ‘wrong’ answers.
              </li>
              <li>
              An update on relevant sections every two years would suffice.
              </li>
              <li>
              Only formally identified National Points of Contact or the Permanent Missions of Member States can submit a national report.
              </li>
              <li>
                <p>Any additional documents can be attached, possibly related to:</p>
                <ul>
                  <li>
                  views on the implementation of the PoA and ITI, including lessons-learned and good practices
                  </li>
                  <li>
                  progress made in the implementation of the PoA and ITI
                  </li>
                  <li>
                  national action plans
                  </li>
                  <li>
                  national targets (e.g. specific indicators and targets)
                  </li>
                  <li>
                  project proposals
                  </li>
                  <li>
                  a list of projects implemented and financial contributions provided/received, including planning and delivery
                  </li>
                  <li>
                  for potential donor countries, any thematic or geographic priorities in funding international assistance projects.
                  </li>
                  <li>
                  national experiences with recent developments in small arms and light weapons manufacturing, technology and design
                  </li>
                  <li>
                  efforts to build confidence and to promote transparency in international cooperation and assistance
                  </li>
                  <li>
                  risk assessments and criteria in arms export control processes
                  </li>
                  <li>
                  cases of diversion, unauthorized transfers, and non-compliance with end-user certificates
                  </li>
                  <li>
                  progress made under indicator 16.4.2
                  </li>
                  <li>
                  gender-related information and initiatives, e.g. national experiences, lessons learned and good practices on mainstreaming a gender perspective, and national action plan on women, peace and security
                  </li>
                  <li>
                  additional information on tracing requests and handling of those requests
                  </li>
                </ul>
              </li>
              <li>
              The reporting template can be downloaded. Draft versions can be saved and printed to allow for internal approval, as required, before submission.
              </li>
              <li>
              Submission of national reports through the online reporting website is strongly encouraged (<a href="https://poa.un-arm.org/login">https://poa.un-arm.org/login</a>). Country-specific passwords can be requested to: conventionalarms-unoda@un.org
              </li>
            </ul>
            <div>
              <input className="btn btn-primary middle-button" name="confirm-announcement-read" type="submit" value="Next" onClick={onClick}/>
            </div>
          </div>
        </div>
      );

