import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import FilePathList from '../File-path-list';
import FilePreview from '../File-preview';
import { getFilenameFromPath } from '../../helpers/fetch-helpers';

const MAX_FILES_PER_QUESTION = 6;
const MAX_FILE_SIZE = 30000000;
const ACCEPTED_FILE_TYPES =
  'image/jpeg, image/png, .pdf, .xls, .xlsx, .doc, .docx, .bmp, .gif, .png';

const maximumReachedMessage = (
  <p className="alert alert-warning alert-pure">
    <small>
      The limit of {MAX_FILES_PER_QUESTION} file uploads per question has been
      reached.
    </small>
  </p>
);

const formatFiles = f => ({
  filePath: f,
});

class UploadedFileManager extends React.Component {
  constructor(props) {
    super();
    this.state = {
      existingFiles: props.value || [],
      pendingFiles: [],
      deletableFiles: [],
    };
  }

  getTotalNumberOfFiles() {
    const { existingFiles, pendingFiles } = this.state;
    return existingFiles.length + pendingFiles.length;
  }

  getMaxMoreFiles() {
    const amount = MAX_FILES_PER_QUESTION - this.getTotalNumberOfFiles();
    return amount < 0 ? 0 : amount;
  }

  getFileNamesFromExistingAndPending() {
    /* Convert Array of previosly uploaded files from Array of Path strings to Array of Filenames */
    const existingFileNameStringArray = this.state.existingFiles.map(path =>
      getFilenameFromPath(path),
    );
    /* Convert Array of files that are pending for upload from Array of File Objects to Array of Filenames */
    const pendingFileNameStringArray = this.state.pendingFiles.map(
      file => file.name,
    );
    /* combining both sets of files */
    return existingFileNameStringArray.concat(pendingFileNameStringArray);
  }

  removeDuplicatesByFileName(acceptedFiles) {
    let cleaned = [];
    const allFilesNameStringArray = this.getFileNamesFromExistingAndPending();
    for (let fileObject of acceptedFiles) {
      const fileObjectName = fileObject.name;
      if (!allFilesNameStringArray.includes(fileObjectName)) {
        cleaned.push(fileObject);
      }
    }
    return cleaned;
  }

  onFileDrop(acceptedFiles) {
    const maxMoreFiles = this.getMaxMoreFiles();
    if (acceptedFiles.length > maxMoreFiles) {
      return;
    } else {
      const noDuplicates = this.removeDuplicatesByFileName(acceptedFiles);
      const pendingFiles = this.state.pendingFiles.concat(noDuplicates);
      this.setState({ pendingFiles: pendingFiles });
      this.props.onFileDropCallback(pendingFiles, this.props.id);
    }
  }

  removePendingFile(fileIndex) {
    const pendingFiles = this.state.pendingFiles.slice();
    pendingFiles.splice(fileIndex, 1);
    this.setState({ pendingFiles: pendingFiles });
    this.props.onFileDropCallback(pendingFiles, this.props.id);
  }

  removeExistingFile(filePath) {
    const alreadyRemoved = this.state.deletableFiles.some(function(delFile) {
      return delFile === filePath;
    });
    if (!alreadyRemoved) {
      const deletableFiles = this.state.deletableFiles.concat([filePath]);
      this.setState({ deletableFiles: deletableFiles });
      this.props.onFileDeleteSelectCallback(
        deletableFiles.map(formatFiles),
        this.props.id,
      );
    }
  }

  cancelRemoveExistingFile(filePath) {
    const position = this.state.deletableFiles.indexOf(filePath);
    if (position < 0) {
      return;
    } else {
      const correctedDeletableList = this.state.deletableFiles.slice();
      correctedDeletableList.splice(position, 1);
      this.setState({ deletableFiles: correctedDeletableList });

      this.props.onFileDeleteSelectCallback(
        correctedDeletableList.map(formatFiles),
      );
    }
  }

  render() {
    const { pendingFiles, existingFiles } = this.state;
    const maximumReached = this.getMaxMoreFiles() < 1;

    const existingFilesList = (
      <FilePathList
        filePaths={this.state.existingFiles}
        deletableFilePaths={this.state.deletableFiles}
        removable={true}
        onRemoveClick={filePath => this.removeExistingFile(filePath)}
        onCancelRemoveClick={filePath =>
          this.cancelRemoveExistingFile(filePath)
        }
      />
    );

    const pendingFilesList = this.state.pendingFiles.length > 0 && (
      <div className="dropzone-preview">
        <div className="alert alert-info">
          <span>
            <span className="glyphicon glyphicon-info-sign" aria-hidden="true">
              &nbsp;
            </span>The files below will be uploaded when you save or submit the
            form.
          </span>
        </div>
        <ul className="list-group">
          {/* looping through HTML5 File objects */}
          {this.state.pendingFiles.map((file, i) => {
            const fileName = file.name;
            return (
              <li key={i} className="list-group-item">
                <FilePreview
                  fileTitle={fileName}
                  removable={true}
                  onRemoveClick={() => this.removePendingFile(i)}
                  className="dropzone-preview-image"
                />{' '}
              </li>
            );
          })}
        </ul>
      </div>
    );

    const addFilesMessageText =
      this.state.existingFiles.length > 0 || this.state.pendingFiles.length > 0
        ? 'Add more files'
        : 'Add files';
    const moreFilesPossibleMessage = (
      <small>
        You can add {this.getMaxMoreFiles()} more files. File names should be
        unique.
      </small>
    );
    const dropzoneWidget = (
      <div className={!!maximumReached && 'hidden'}>
        <Dropzone
          disableClick={!!maximumReached}
          maxSize={MAX_FILE_SIZE}
          accept={ACCEPTED_FILE_TYPES}
          onDrop={this.onFileDrop.bind(this)}
          inputProps={{ id: this.props.id }}
          style={{}}
        >
          {moreFilesPossibleMessage}
          <Button className="btn-default btn-block" disabled={!!maximumReached}>
            <i className="glyphicon glyphicon-plus" />
            <span> {addFilesMessageText} </span>
          </Button>
        </Dropzone>
      </div>
    );

    const existingFilesMessage = (
      <span>
        You have <strong>{existingFiles.length}</strong> previously uploaded{' '}
        {existingFiles.length === 1 ? 'file' : 'files'}
      </span>
    );
    const pendingFilesMessage = pendingFiles.length > 0 && (
      <span>
        {' '}
        and <strong>{pendingFiles.length}</strong> new{' '}
        {pendingFiles.length === 1 ? 'file' : 'files'} ready for uploading
      </span>
    );
    const fileOverviewMessage = (
      <p className="alert alert-info">
        {existingFilesMessage}
        {pendingFilesMessage}
      </p>
    );

    return (
      <div className="container-fluid file-upload-section">
        {fileOverviewMessage}
        <div>
          {existingFilesList}
          {pendingFilesList}
          {maximumReached ? maximumReachedMessage : dropzoneWidget}
        </div>
      </div>
    );
  }
}

const propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.array,
  onFileDropCallback: PropTypes.func,
  onFileDeleteSelectCallback: PropTypes.func,
};
UploadedFileManager.propTypes = propTypes;

export default UploadedFileManager;
