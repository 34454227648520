import React from 'react';
import PropTypes from 'prop-types';
import FilePreview from './File-preview';
import { getFilenameFromPath } from '../helpers/fetch-helpers';

const FilePathList = props => {
  const {
    filePaths,
    removable,
    onRemoveClick,
    onCancelRemoveClick,
    deletableFilePaths
  } = props;
  /* deletable files is an array of file-url-strings */

  return filePaths && filePaths.length > 0 ? (
    <ul className="list-group file-path-list">
      {filePaths.map((filePath, i) => {
        const muted =
          deletableFilePaths && deletableFilePaths.indexOf(filePath) > -1;
        const fileLink = (
          <a href={filePath} target="_blank" rel="noopener noreferrer">
            {' '}
            {getFilenameFromPath(filePath)}
          </a>
        );

        return (
          <li key={i} className="list-group-item">
            <FilePreview
              fileTitle={fileLink}
              muted={muted}
              removable={removable}
              onRemoveClick={() => onRemoveClick(filePath)}
              onCancelRemoveClick={() => onCancelRemoveClick(filePath)}
            />
          </li>
        );
      })}
    </ul>
  ) : (
    <span> No files</span>
  );
};

const propTypes = {
  filePaths: PropTypes.array /* an array of File-url strings */
};
FileList.propTypes = propTypes;

export default FilePathList;
