/* Thats the official layout component */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import PoaNavigation from './Poa-navigation';
import PoaFooter from './Poa-footer';
import jwtAuth from '../helpers/auth';
import { COUNTRY_INFO_ENDPOINT } from '../endpoints';
import { TOKEN_EXTENSION_SAFETY_MARGIN_SECONDS } from '../settings.js';
import Promise from 'promise-polyfill';
import ErrorPage from './Error-page';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/main.css';
import '../styles/poa-reporting.css';
import '../styles/react-spinner.css';

if (!window.Promise) {
  window.Promise = Promise;
}

const propTypes = { children: PropTypes.object };

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loggedIn: jwtAuth.loggedIn(),
      errorOccured: false,
      country: null,
      user: null,
      userIsStaff: null,
    };
  }

  updateAuth(loggedIn) {
    this.setState({
      loggedIn,
    });
    if (loggedIn === true) {
      this.setState({
        refreshIntervalId: this.checkTimeRemainingBeforeTokenExtension(),
      });
    }
    /* we're resetting the user, otherwise the old user will still be propagated to children until fetchCountryInfo returns its promise... */
    if (loggedIn === false) {
      clearInterval(this.state.refreshIntervalId);
      this.setState({
        country: null,
        user: null,
        userIsStaff: null,
      });
    }
  }

  updateCountryInfo(apiInfo) {
    this.setState({
      country: apiInfo.country,
      user: apiInfo.user,
      userIsStaff: apiInfo.country.countryId === null,
    });
    /* until this is set explicitely in the api, we assume that users with no country attached are staff members! */
  }

  fetchCountryInfo() {
    fetch(COUNTRY_INFO_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'jwt ' + jwtAuth.getToken(),
      },
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.status);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        this.updateCountryInfo(response.data);
      })
      .catch(err => {
        this.setState({
          errorOccured: true,
        });
        console.log(err);
      });
  }

  componentWillMount() {
    jwtAuth.onChange = this.updateAuth.bind(this);
    jwtAuth.afterLogin = this.fetchCountryInfo.bind(this);
    jwtAuth.login(); /* Testing if the user is already logged in */
  }

  checkTimeRemainingBeforeTokenExtension() {
    const tokenExpireTime = jwtAuth.parseJwt(jwtAuth.getToken())['exp'];
    const safetyMargin = TOKEN_EXTENSION_SAFETY_MARGIN_SECONDS;
    const refreshIntervalId = setInterval(() => {
      const currentTime = Math.floor(
        new Date().getTime() / 1000,
      ); /* in seconds */
      const secondsBeforeExpiration = tokenExpireTime - currentTime;
      if (secondsBeforeExpiration <= safetyMargin) {
        jwtAuth.refreshToken();
        clearInterval(refreshIntervalId);
      }
    }, 1000);
    return refreshIntervalId;
  }

  /* providing children with necessary props */
  renderChildren() {
    const countryIsoCode = this.state.country
      ? this.state.country.isoAlpha_3
      : null;
    const { user, userIsStaff } = this.state;

    if (this.props.children) {
      return React.Children.map(this.props.children, child =>
        React.cloneElement(child, {
          countryIsoCode: countryIsoCode,
          user: user,
          userIsStaff: userIsStaff,
        }),
      );
    }
    return 'Welcome to POA';
  }

  getCountryName(countryBlob, languageId) {
    const langId = languageId ? languageId : 3;
    const languageBlob = _.findWhere(countryBlob['countryMetaSet'], {
      languageId: langId,
    });
    return languageBlob !== undefined ? languageBlob['name'] : null;
  }

  render() {
    return (
      <div>
        <PoaNavigation
          loggedIn={this.state.loggedIn}
          countryName={
            this.state.country ? this.getCountryName(this.state.country) : null
          }
          userName={this.state.user ? this.state.user.username : null}
          userIsStaff={this.state.userIsStaff}
        />

        {this.state.errorOccured ? (
          <ErrorPage />
        ) : (
          <div className="container page-content">
            <section>{this.renderChildren()}</section>
          </div>
        )}

        <PoaFooter />
      </div>
    );
  }
}

/* You can declare that a prop is a specific JS primitive.E.g. array, bool, function, object,  */
App.propTypes = propTypes;

export default App;
