import React from 'react';
import _ from 'underscore';
import { REPORTLIST_ENDPOINT } from '../endpoints';
import jwtAuth from '../helpers/auth';
import Spinner from 'react-spinner';
import Select from 'react-select';
import { browserHistory } from 'react-router';
import { doPatchWinterfell, createCopy } from '../helpers/fetch-helpers';
import { copyWarning } from '../helpers/prompts';

const languageOptions = [
  { value: 'eng', label: 'English' },
  { value: 'ara', label: 'Arabic' },
  { value: 'chi', label: 'Chinese' },
  { value: 'fra', label: 'French' },
  { value: 'rus', label: 'Russian' },
  { value: 'spa', label: 'Spanish' },
];

export default class NewReportCreator extends React.Component {
  constructor() {
    super();
    this.state = {
      isCreating: false,
      error: false,
      reportLanguage: 'eng',
      copyReport: false,
      copyReportCandidate: null,
    };
  }

  componentWillMount() {
    this.getCopyReportCandidateId(this.props.countryIsoCode);
  }

  async getCopyReportCandidateId(countryIsoCode) {
    const endpoint =
      REPORTLIST_ENDPOINT + '?country__iso_alpha_3=' + countryIsoCode;
    const getReports = () => {
      return fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'jwt ' + jwtAuth.getToken(),
        },
      })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response;
        })
        .then(response => response.json())
        .then(response => response.data);
    };
    let reports = [];
    try {
      reports = await getReports();
    } catch (err) {
      console.error(err);
    }
    if (reports.length === 0) {
      return;
    }
    const candidateReports = _.filter(
      reports,
      r =>
        r['attributes']['questionDraft'] === false &&
        r['attributes']['questionArchived'] === false &&
        r['attributes']['questionReportingYear'] >= 2018,
    );

    const report = _.max(
      candidateReports,
      r => r['attributes']['questionReportingYear'],
    );

    if (report) {
      this.setState({
        copyReportCandidate: {
          reportYear: report['attributes']['questionReportingYear'],
          reportId: report['id'],
        },
      });
    }
  }

  toggleCopyReport() {
    this.setState({ copyReport: !this.state.copyReport });
  }

  buildPostBodyFromPropsAndState() {
    return {
      questionReportingYear: this.props.reportYear,
      questionLanguageIso_639_2: this.state.reportLanguage,
    };
  }

  copyFromPreviousReport() {
    createCopy(this.state.copyReportCandidate.reportId, this.props.reportYear)
      .then(data => data.questionFormResponseId)
      .then(newReportId => {
        const postBody = this.buildPostBodyFromPropsAndState();
        const callbacks = {
          onDoing: () => {
            this.setState({
              isCreating: true,
              error: false,
            });
          },
          onSuccess: () => {
            this.setState({
              isCreating: false,
              error: false,
            });
            browserHistory.push('/report/' + newReportId + '/edit');
          },
          onFail: () => {
            this.setState({
              isCreating: false,
              error: true,
            });
          },
        };
        return doPatchWinterfell(newReportId, postBody, callbacks);
      })
      .catch(err => {
        this.setState({
          isCreating: false,
          error: true,
        });
      });
  }

  postToListResource() {
    const postBody = this.buildPostBodyFromPropsAndState();
    fetch(REPORTLIST_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'jwt ' + jwtAuth.getToken(),
      },
      body: JSON.stringify(postBody),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        this.setState({
          isCreating: false,
          error: false,
        });
        const reportId = response.data.questionFormResponseId;
        browserHistory.push('/report/' + reportId + '/edit');
      })
      .catch(err => {
        this.setState({
          isCreating: false,
          error: true,
        });
      });
  }

  onSubmit() {
    this.setState({
      isCreating: true,
    });

    if (this.state.copyReport) {
      if (window.confirm(copyWarning)) {
        this.copyFromPreviousReport();
      } else {
        this.setState({
          isCreating: false,
        });
      }
    } else {
      this.postToListResource();
    }
  }

  render() {
    return (
      <div>
        <h3>
          <span
            className="glyphicon glyphicon-list-alt"
            style={{ marginRight: '10px' }}
            aria-hidden="true"
          />
          PoA Report {this.props.reportYear}{' '}
          <small>covering years {this.props.coveringYears.join('-')}</small>
        </h3>

        <Select
          name="reportLanguage"
          value={this.state.reportLanguage}
          options={languageOptions}
          searchable={false}
          clearable={false}
          className={this.props.className}
          onChange={lang => this.setState({ reportLanguage: lang.value })}
        />

        {this.state.copyReportCandidate && (
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                onClick={() => this.toggleCopyReport()}
                name="copy"
                checked={this.state.copyReport}
                id="copyReport"
              />
              Copy previous report ({this.state.copyReportCandidate.reportYear})
              and update information for new submission. (Answers to new questions and year-specific data need to be provided)
              <br />
            </label>
          </div>
        )
        /*<span className="help-block">No previous report can be used for copying.</span>*/
        }
        <p>&nbsp;</p>

        {this.state.isCreating && <Spinner />}
        {this.state.error &&
          !this.state.isCreating && (
            <div className="alert alert-danger" role="alert">
              Create not successful. Please try again.
            </div>
          )}
        <input
          className="btn btn-primary pull-right"
          name="create_new_report"
          type="submit"
          value="Create"
          onClick={() => this.onSubmit()}
          disabled={this.state.isCreating}
        />
      </div>
    );
  }
}
