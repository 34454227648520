import React, { Component } from 'react';
import { Link } from 'react-router';

export default class PoaNavigation extends Component {
  render() {
    return (
      <div className="country-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-xs-offset-3">
              <h1>
                UN Programme of Action <br />{' '}
                <span>National Reporting</span>
              </h1>
              {this.props.loggedIn && (
                <h2>
                  {' '}
                  <small> {this.props.isoAlpha_3} </small>{' '}
                  {this.props.countryName}{' '}
                </h2>
              )}
            </div>
            <div className="authentication-area col-xs-3">
              {this.props.loggedIn && (
                <div>
                  <p>
                    Welcome{' '}
                    <strong>
                      {' '}
                      {this.props.userIsStaff
                        ? 'UNODA staff'
                        : this.props.userName}{' '}
                    </strong>{' '}
                  </p>
                  <Link to="/logout">
                    <span className="btn btn-primary">Log Out</span>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
