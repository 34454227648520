import React, { Component } from 'react';
import auth from '../helpers/auth';
import { Link } from 'react-router';

export default class Logout extends Component {
  componentDidMount() {
    auth.logout();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">
            <br />
            <br />
            <br />
            <br />
            <h3 className="text-center"> You are now logged out.</h3>
            <Link to="/login">
              <h4 className="text-center">Login again</h4>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
