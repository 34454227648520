import React from 'react';
export default (
  <div className="container-fluid">
    <p>
      a) PoA Reporting Template for hard copy submission (MS Word) [<a
        href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-A.docx" target="_blank"
        rel="noopener noreferrer"
      >
        Arabic
      </a>,{' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-C.docx" target="_blank" rel="noopener noreferrer">
        Chinese
      </a>,{' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-E.docx" target="_blank" rel="noopener noreferrer">
        English
      </a>,{' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-F.docx" target="_blank" rel="noopener noreferrer">
        French
      </a>,{' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-R.docx" target="_blank" rel="noopener noreferrer">
        Russian
      </a>,{' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/PoA+Reporting+Template+2022+-+Update-S.docx" target="_blank" rel="noopener noreferrer">
        Spanish
      </a>].&nbsp;
    </p>
    <p>
      b){' '}
      <a href="https://unoda-poa.s3.amazonaws.com/public/UNPoA+Reporting+Tool+Quick+Guide+2022.pdf" target="_blank" rel="noopener noreferrer">
        Quickguide
      </a>{' '}
      on the UNPoA Online Reporting Tool.
    </p>
    <p>
      c) <a
        href="https://unoda-poa.s3.amazonaws.com/public/22-nve-file-PoA+reporting-revised+2022+02+22.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Note verbale, February 2022
      </a>
    </p>
  </div>
);
