import React from 'react';
import { ADMIN_REPORT_OVERVIEW } from '../endpoints';
import { Link } from 'react-router';
import { Button } from 'react-bootstrap';
import { getCoveringYears } from '../helpers/various-helpers';

export default ({
  country,
  reportingYear,
  reportId,
  isStaff,
  isDraft,
  isArchived,
  hasUnsaved,
  disableButton,
  patchingCallbacks,
  pdfLink,
  onSave,
}) => {
  const backLink = isStaff ? (
    <a href={ADMIN_REPORT_OVERVIEW}>
      <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />Back
      to admin overview
    </a>
  ) : (
    <Link to="/report">
      <span className="glyphicon glyphicon-chevron-left" aria-hidden="true" />{' '}
      Country home{' '}
    </Link>
  );

  const reportStatus = (
    <div className="report-details">
      Status:{' '}
      <span className={isDraft ? 'label label-default' : 'label label-warning'}>
        {' '}
        {isDraft ? 'Draft' : 'Submitted'}{' '}
      </span>
    </div>
  );

  const downloadSection = (
    <div className="download-section">
      <Button className="btn-default" disabled={hasUnsaved}>
        <a href={hasUnsaved ? null : pdfLink}>Download to Print Report</a>
        {hasUnsaved && (
          <div>
            <small>Please save report to active this button</small>
          </div>
        )}
      </Button>
      {hasUnsaved && (
        <Button
          className="btn btn-primary"
          style={{ marginLeft: '15px' }}
          onClick={onSave}
        >
          Save
        </Button>
      )}
    </div>
  );

  const title = (
    <span>
      {isStaff && <h3>{country}</h3>}
      <h1>
        PoA report {reportingYear}
        <small> covering {getCoveringYears(reportingYear)} </small>
      </h1>
    </span>
  );

  return (
    <div className="page-header">
      {backLink}
      {title}
      {reportStatus}
      {downloadSection}
    </div>
  );
};
