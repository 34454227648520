import React from 'react';

const FilePreview = props => {
  const {
    fileTitle,
    removable,
    onRemoveClick,
    onCancelRemoveClick,
    muted
  } = props;

  return (
    <div className={'file-preview ' + (muted ? 'file-preview-muted' : '')}>
      <span className="file-preview-name">
        <span className="glyphicon glyphicon-file" aria-hidden="true" />
        <span className="filename">{fileTitle}</span>
      </span>

      <span className="file-preview-action pull-right">
        {muted && (
          <span
            style={{ color: 'green' }}
            onClick={
              onRemoveClick &&
              ((proxy, event) => onCancelRemoveClick(fileTitle))
            }
          >
            Cancel delete{' '}
            <span className="glyphicon glyphicon-retweet" aria-hidden="true">
              &nbsp;
            </span>
          </span>
        )}

        {removable &&
          !muted && (
            <span
              onClick={
                onRemoveClick && ((proxy, event) => onRemoveClick(fileTitle))
              }
            >
              Delete{' '}
              <span
                className="glyphicon glyphicon-remove close"
                aria-hidden="true"
              >
                &nbsp;
              </span>
            </span>
          )}
      </span>
    </div>
  );
};

export default FilePreview;
