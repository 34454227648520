import React, { Component } from 'react';
import _ from 'underscore';
import jwtAuth from '../helpers/auth';
import {
  REPORTLIST_ENDPOINT,
  REPORTLIST_ENDPOINT_COMPREHENSIVE,
  ADMIN_REPORT_OVERVIEW,
} from '../endpoints';
import Promise from 'promise-polyfill';
import NewReportWidget from './New-report-widget';
import Spinner from 'react-spinner';
import ReportsTableNonComprehensive from './Reports-table-non-comprehensive';
import ReportsTableComprehensive from './Reports-table-comprehensive';
import { GenericStatusMessage } from './edit-report-components/Status-messages';
import { EDIT_TYPES } from '../helpers/permissions.js';
import { parseReportId } from '../helpers/various-helpers';
import Footer from './Footer';
if (!window.Promise) {
  window.Promise = Promise;
}

const REPORTLIST_SETTINGS = {
  comprehensive: {
    endpoint: REPORTLIST_ENDPOINT_COMPREHENSIVE,
    isComprehensive: true,
  },
  nonComprehensive: {
    endpoint: REPORTLIST_ENDPOINT,
    isComprehensive: false,
  },
};

/* comprehensive = endpoint /reports
nonComprehensive = endpoint /winterfell-answer */
const REPORTLIST_SETTING = REPORTLIST_SETTINGS['comprehensive'];

const nowYear = new Date().getFullYear();
const currentReportingPeriodYear = 2024 //biannual reporting rythm

const getEditTypePermission = ({
  isLegacy,
  isArchived,
  isDraft,
  reports,
  reportingYear,
}) => {
  const currentReportingPeriodReports = reports.filter(
    r =>
      r.attributes.reportingYear === currentReportingPeriodYear &&
      r.attributes.archived === false,
  );
  const allowUpdateThroughCopy =
    reportingYear === currentReportingPeriodYear &&
    currentReportingPeriodReports.length === 1;
  if (
    isLegacy === true ||
    isArchived === true ||
    reportingYear !== currentReportingPeriodYear
  ) {
    return EDIT_TYPES.NOEDIT;
  }
  if (isDraft === true) {
    return EDIT_TYPES.PATCH;
  } else {
    if (allowUpdateThroughCopy) {
      return EDIT_TYPES.COPY;
    }
  }
  return EDIT_TYPES.NOEDIT;
};

export default class CountryHomePage extends Component {
  constructor() {
    super();
    this.state = {
      fetchError: false,
      countryReports: null,
      containsDrafts: false,
    };
  }

  componentDidMount() {
    if (this.props.countryIsoCode) {
      this.fetchCountryReports(this.props.countryIsoCode);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userIsStaff === true) {
      window.location.assign(ADMIN_REPORT_OVERVIEW);
    }
    if (nextProps.countryIsoCode) {
      this.fetchCountryReports(nextProps.countryIsoCode);
    }
  }

  onFail() {
    this.setState({ fetchError: true });
  }

  updateState(apiData) {
    const reports = apiData;
    this.setState({
      countryReports: reports,
      containsDrafts: this.containsDrafts(reports),
    });
  }

  fetchCountryReports(countryIsoCode) {
    const endpoint =
      REPORTLIST_SETTING.endpoint + '?country__iso_alpha_3=' + countryIsoCode;
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'jwt ' + jwtAuth.getToken(),
      },
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        this.updateState(response.data);
      })
      .catch(err => {
        this.onFail();
      });
  }

  pickReportFieldsForTable(reports) {
    const reformatted = reports.map(r => {
      let reformatted = {};
      reformatted = { ...r['attributes'] };
      reformatted['link'] = r['links']['self'];
      reformatted['pdfLink'] = REPORTLIST_SETTING.isComprehensive
        ? r['links']['reportFile']
        : r['links']['pdf'];
      reformatted['id'] = r['id'];
      const isLegacy =
        r.id
          .split('')
          .splice(0, 2)
          .join('') === 'le';
      reformatted['isLegacy'] = isLegacy;
      reformatted['editType'] = getEditTypePermission({
        isLegacy: isLegacy,
        isArchived: r.attributes.archived,
        isDraft: r.attributes.draft,
        reports: reports,
        reportingYear: r.attributes.reportingYear,
      });
      return reformatted;
    });
    return reformatted;
  }

  hasNewestReportAlready(reports, year) {
    return REPORTLIST_SETTING.isComprehensive
      ? _.some(reports, r => r['attributes']['reportingYear'] === year)
      : _.some(reports, r => r['attributes']['questionReportingYear'] === year);
  }

  containsDrafts(reports) {
    if (REPORTLIST_SETTING.isComprehensive) {
      return reports.length > 0
        ? _.some(reports, r => r['attributes']['draft'] === true)
        : false;
    } else {
      return reports.length > 0
        ? _.some(reports, r => r['attributes']['questionDraft'] === true)
        : false;
    }
  }

  render() {
    const { countryReports } = this.state;
    const reportsTableType = () =>
      REPORTLIST_SETTING.isComprehensive ? (
        <ReportsTableComprehensive
          reports={this.pickReportFieldsForTable(countryReports)}
        />
      ) : (
        <ReportsTableNonComprehensive
          reports={this.pickReportFieldsForTable(countryReports)}
        />
      );
    const panelBody = (
      <div className="panel-body">
        {countryReports ? reportsTableType() : <Spinner />}
      </div>
    );

    return (
      <div className="main-content">
        <div className="row">
          <div className="col-md-8">
            <div className="panel">
              <div className="panel-heading">
                <h3>Your PoA reports</h3>
                <p>
                  This table shows the past submissions by your government (as
                  well as reports that are still in draft state)
                </p>
              </div>
              {this.state.fetchError ? (
                <GenericStatusMessage
                  status="error"
                  message="An error occured."
                />
              ) : (
                panelBody
              )}
            </div>
          </div>
          <div className="col-md-4">
            {countryReports && (
              <NewReportWidget
                year={
                  this.hasNewestReportAlready(
                    countryReports,
                    currentReportingPeriodYear,
                  )
                    ? null
                    : currentReportingPeriodYear
                }
                countryIsoCode={this.props.countryIsoCode}
              />
            )}
          </div>
        </div>
        {Footer}
      </div>
    );
  }
}
