/*
  makeFormReadOnly event handlers are used make the form read-only. They modify the DOM element of the Winterfell form itself,
  because the Winterfell component does not offer an api to do this
*/

export const makeFormEditableEventHandlers = {
  onRender: function() {
    const hideSubmitButton = false;
    treatButtonsVisibility(hideSubmitButton);
    //document.querySelector("form.winterfell-form").removeAttribute("novalidate");
  },
  onSwitchPanel: function(panel) {
    const hideSubmitButton = false;
    treatButtonsVisibility(hideSubmitButton);
  }
};

export const makeFormReadOnlyEventHandlers = {
  onRender: function() {
    const hideSubmitButton = true;
    treatButtonsVisibility(hideSubmitButton);
    //document.querySelector("form.winterfell-form").setAttribute("novalidate", "novalidate");
  },
  onSwitchPanel: function(panel) {
    const hideSubmitButton = true;
    treatButtonsVisibility(hideSubmitButton);
  }
};

function treatButtonsVisibility(hideSubmit) {
  const buttons = document.querySelectorAll('form.winterfell-form button');
  for (const b of buttons) {
    b.style.display = 'block';
    if (
      hideSubmit === true &&
      (b.textContent === 'Submit' || b.textContent === 'Save')
    ) {
      b.style.display = 'none';
    }
  }
  if (hideSubmit === true) {
    const messages = document.querySelectorAll('.under-development-message');
    for (const m of messages) {
      m.style.display = 'none';
    }
  }
}
