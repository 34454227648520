const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8000/poa/2024/";
const API_ADMIN_URL = process.env.REACT_APP_ADMIN_URL ? process.env.REACT_APP_ADMIN_URL : "http://localhost:8000/admin/un/";

module.exports = {
  REPORTLIST_ENDPOINT: API_URL + 'winterfell-answer/',
  REPORTLIST_ENDPOINT_COMPREHENSIVE: API_URL + 'reports/',
  WINTERFELL_SCHEMA_ENDPOINT: API_URL + 'winterfell-schema/',
  COUNTRY_INFO_ENDPOINT: API_URL + 'user-meta/',
  FILE_SUBMISSION_ENDPOINT: API_URL + 'winterfell-file-upload/',
  FILE_DELETION_ENDPOINT: API_URL + 'winterfell-file-upload/?path=',
  ADMIN_REPORT_OVERVIEW: API_ADMIN_URL + 'formresponse/',
  AUTH_ENDPOINT: API_URL + 'api-token-auth/',
  TOKENREFRESH_ENDPOINT: API_URL + 'api-token-refresh/',
  PDF_ENDPOINT: API_URL + 'pdf/',
  COPY_ENDPOINT: API_URL + 'winterfell-copy/',
};
