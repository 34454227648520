import React from 'react';
import { Link } from 'react-router';
import Spinner from 'react-spinner';
import NoMatch from './No-match';
import { ADMIN_REPORT_OVERVIEW } from '../endpoints';
import WinterfellReadOnly from './Winterfell-read-only';
import {
  fetchReportData,
  fetchWinterfellSchema,
} from '../helpers/fetch-helpers';
import { getCoveringYears } from '../helpers/various-helpers';

export default class ReportReadOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      winterfellSchema: null,
      winterfellData: null,
      dataFetchError: false,
    };
  }

  dataFetchCallbacks = {
    onSuccess: data => {
      this.setState({ winterfellData: data });
    },
    onFail: () => this.setState({ dataFetchError: true }),
  };

  schemaFetchCallbacks = {
    onSuccess: schema => this.setState({ winterfellSchema: schema }),
    onFail: () => this.setState({ dataFetchError: true }),
  };

  fetchReport(winterfellAnswerId, countryIsoCode) {
    fetchReportData(
      winterfellAnswerId,
      countryIsoCode,
      this.dataFetchCallbacks,
    ).then(({ winterfellFormId, language }) =>
      fetchWinterfellSchema(
        winterfellFormId,
        this.schemaFetchCallbacks,
        this.props.location.query.lang || language,
      ),
    );
  }

  getStatus(isDraft) {
    return isDraft ? 'Draft' : 'Submitted';
  }

  componentWillReceiveProps(nextProps) {
    const { countryIsoCode, userIsStaff } = nextProps;
    this.setState({
      userIsStaff: userIsStaff,
    });
    const { winterfellAnswerId } = this.props.params;
    /* countryIsoCode will be an empty string (not null) when an admin/staff is logged in */
    if (countryIsoCode !== null) {
      this.fetchReport(winterfellAnswerId, countryIsoCode);
    }
  }

  render() {
    const {
      dataFetchError,
      userIsStaff,
      winterfellSchema,
      winterfellData,
    } = this.state;
    if (dataFetchError) {
      return <NoMatch />;
    }

    const report =
      winterfellSchema && winterfellData ? (
        <div>
          <div className="winterfell-container">
            <WinterfellReadOnly
              schema={winterfellSchema}
              questionAnswers={Object.assign({}, winterfellData)}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      );

    const reportDetails = winterfellData ? (
      <div className="report-details">
        Status:{' '}
        <span
          className={
            winterfellData.questionDraft === false
              ? 'label label-warning'
              : 'label label-default'
          }
        >
          {' '}
          {this.getStatus(winterfellData.questionDraft)}{' '}
        </span>
      </div>
    ) : null;

    if (winterfellData) {
      return (
        <div className="main-content poa-questionaire">
          {userIsStaff ? (
            <a href={ADMIN_REPORT_OVERVIEW}>
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              />Back to admin overview
            </a>
          ) : (
            <Link to="/report">
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              />{' '}
              Country home{' '}
            </Link>
          )}
          <div className="page-header">
            {this.props.userIsStaff && (
              <h3>{winterfellData.questionCountry}</h3>
            )}
            <h1>
              PoA report {winterfellData.questionReportingYear}
              <small>
                {' '}
                covering{' '}
                {getCoveringYears(winterfellData.questionReportingYear)}{' '}
              </small>
            </h1>
            <h2>
              <small>{reportDetails}</small>
            </h2>
          </div>
          <div>
            <div>{report}</div>
          </div>
        </div>
      );
    }
    return <Spinner />;
  }
}
