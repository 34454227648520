import React, { Component } from 'react';
import { Table, unsafe } from 'reactable';
import _ from 'underscore';

export default class ReportsTableNonComprehensive extends Component {
  constructor(props) {
    super();
    this.state = {
      reports: this.renderDataForTable(props.reports),
      showArchived: false
    };
  }

  renderDataForTable(reportsArray) {
    const renderedData = reportsArray.map(r => {
      const viewReportUrl = `/report/${r.id}`;
      const editReportUrl = `/report/${r.id}/edit`;
      const viewReportLink = `<a href=${viewReportUrl}>View report</a>`;
      const editReportLink = `<a href=${editReportUrl}>Edit report</a>`;
      const pdfLink = r.pdfLink
        ? `<a href=${r.pdfLink}>Download as PDF</a>`
        : null;
      const submittedSign =
        "<span class='glyphicon glyphicon-ok-circle text-success' aria-hidden='true'></span>&nbsp;Submitted";
      const templateVersionName = r.templateVersion + ' Template';
      const reportingYear = `<strong>${r.questionReportingYear}</strong>`;
      return Object.assign(r, {
        questionReportingYear: unsafe(reportingYear),
        link: unsafe(viewReportLink),
        pdfLink: unsafe(pdfLink),
        templateVersion: templateVersionName,
        edit: r.questionDraft === true ? unsafe(editReportLink) : 'Submitted',
        questionDraft:
          r.questionDraft === true ? 'Draft' : unsafe(submittedSign),
        questionArchived:
          r.questionArchived === true ? 'Archived' : 'Not archived'
      });
    });
    return renderedData;
  }

  onFilterChange(event) {
    this.setState({
      showArchived: !this.state.showArchived
    });
  }

  render() {
    const that = this;
    const { showArchived, reports } = this.state;
    const finalData =
      showArchived === false
        ? _.filter(reports, d => d.questionArchived !== 'Archived')
        : reports;
    const columns = [
      { key: 'questionReportingYear', label: 'Year' },
      { key: 'questionDraft', label: 'Status' },
      { key: 'questionArchived', label: 'Archived' },
      { key: 'questionLanguage', label: 'Language' },
      { key: 'templateVersion', label: 'Template version' },
      { key: 'link', label: 'Link' },
      { key: 'pdfLink', label: 'PDF' },
      { key: 'edit', label: 'Edit report' }
    ];
    if (showArchived === false) {
      columns.splice(2, 1);
    }
    return (
      <span>
        <div className="checkbox">
          <label>
            <input
              onChange={that.onFilterChange.bind(this)}
              type="checkbox"
              name="showArchived"
              checked={this.state.showArchived}
            />Show archived reports
          </label>
        </div>
        <Table
          className="table table-striped table-bordered"
          data={finalData}
          sortable={true}
          noDataText="There are no reports using the 2017 template"
          columns={columns}
        />
      </span>
    );
  }
}
