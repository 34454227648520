import jwtLib from 'jsonwebtoken';
import { AUTH_ENDPOINT, TOKENREFRESH_ENDPOINT } from '../endpoints';
import Promise from 'promise-polyfill';
if (!window.Promise) {
  window.Promise = Promise;
}

const jwtAuth = {
  login(username, password, handleLoginState) {
    handleLoginState = arguments[arguments.length - 1];
    if (localStorage.token) {
      if (handleLoginState) handleLoginState(true);
      this.onChange(true);
      this.afterLogin();
      return;
    }

    fetch(AUTH_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusMessage);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        localStorage.token = response.data.token;
        if (handleLoginState) {
          handleLoginState(true);
        }
        this.onChange(true);
        this.afterLogin();
      })
      .catch(err => {
        if (handleLoginState) handleLoginState(false, err);
        this.onChange(false);
      });
  },

  getToken() {
    return localStorage.token;
  },

  logout(handleLoginState) {
    delete localStorage.token;
    if (handleLoginState) handleLoginState();
    this.onChange(false);
  },

  loggedIn() {
    const jwtString = localStorage.token;
    if (!jwtString) {
      return false;
    }
    const jwt = jwtLib.decode(jwtString);
    const is_expired = jwt.exp < Date.now() / 1000;
    if (is_expired) {
      delete localStorage.token;
      return false;
    }
    return true;
  },

  onChange() {
    /* function body will be set from outside Component ... */
  },

  afterLogin() {
    /* function body will be set from outside Component ... */
  },

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  },

  refreshToken() {
    fetch(TOKENREFRESH_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: jwtAuth.getToken(),
      }),
    })
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.statusMessage);
        }
        return response;
      })
      .then(response => response.json())
      .then(response => {
        localStorage.token = response.data.token;
        this.onChange(true);
      })
      .catch(error => {
        this.onChange(false);
      });
  },
};

export default jwtAuth;
